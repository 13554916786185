<template>
  <a-modal :visible="true" title="新增人员" :maskClosable="false" @ok="submit" @cancel="cancel" :width="930"
    :confirm-loading="confirmLoading" :closable="false">
    <a-form layout="inline">
      <a-form-item label="账号：">
        <a-input v-model.trim="searchForm.account" placeholder="请输入账号" class="inputClass" />
      </a-form-item>
      <a-form-item label="姓名：">
        <a-input v-model.trim="searchForm.userName" placeholder="请输入姓名" class="inputClass" />
      </a-form-item>
      <a-form-item label="手机号：">
        <a-input v-model.trim="searchForm.phoneNumber" placeholder="请输入手机号" class="inputClass" />
      </a-form-item>
      <a-button type="primary" @click="search" style="margin-top: 3px" :disabled="searchDisabled"
        :loading="searchBtnLoading">
        查询
      </a-button>
    </a-form>

    <a-table :row-selection="{
      selectedRowKeys: table.selectedRowKeys,
      onChange: selectedStaff,
      type: 'radio',
    }" :columns="table.columns" :data-source="table.dataInfo" :pagination="false"
      :rowKey="(record, index) => record.userId" class="my-20" bordered style="margin-top: 20px" />

    <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol" ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item style="margin-top: 20px;" label="账号：" prop="account">
        <div style="display: flex;align-items: center;"><a-input style="width: 400px;" disabled v-model.trim="form.account"
            placeholder="请选择" />
        </div>

      </a-form-model-item>
      <a-form-model-item label="姓名：" prop="userName">
        <div style="display: flex;align-items: center;">
        <a-input style="width: 400px;" disabled v-model.trim="form.userName"
            placeholder="请选择" />
        </div>

      </a-form-model-item>
      <a-form-model-item label="手机号：" prop="phoneNumber">
        <div style="display: flex;align-items: center;"><a-input style="width: 400px;" disabled v-model.trim="form.phoneNumber"
            placeholder="请选择" />
        </div>

      </a-form-model-item>
      <a-form-model-item label="飞书open_id：" prop="feishuid">
        <a-input v-model.trim="form.openId" placeholder="" class="inputClass" />
      </a-form-model-item>
      <a-form-model-item label="角色：" style=" margin-top: 20px" prop="roleIds">
        <a-select v-model="form.roleIds" placeholder="请选择角色类型" mode="multiple"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option v-for="item in roleTypeList" :value="item.roleTypeId" :key="item.roleTypeId">{{
            item.roleTypeName
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="部门：" prop="organizationId">
        <a-tree-select v-model="form.organizationId" :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
          :tree-data="departmentList" placeholder=" 请选择部门" tree-default-expand-all :replaceFields="{
            children: 'childList',
            title: 'organizationName',
            key: 'organizationId',
            value: 'organizationId',
          }" :getPopupContainer="(triggerNode) => {
  return triggerNode.parentNode || document.body;
}
  ">
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="服务项目：" style=" margin-top: 20px" prop="projectIdList">
        <a-select v-model="form.projectIdList" placeholder="请选择服务项目" mode="multiple"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option v-for="item in projectList" :value="item.projectId" :key="item.projectId">{{
            item.projectName
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import * as api from '@/api/demandPoolShunyi';
import { SUCCESS_CODE } from '@/config';
import { addStaffSearchForm, addStaffTableConfig } from '../../../help';
import { mapState } from 'vuex';
export default {
  name: 'AddStaff',
  props: {
    showAddStaff: {
      type: Boolean,
      default: false,
    },
    // 外部列表选中部门
    listSelectedDepartment: {
      type: String,
      default: '',
    },
  },
  computed: {
    searchDisabled() {
      return this.searchForm.account || this.searchForm.userName || this.searchForm.phoneNumber ? false : true;
    },
    ...mapState({
      roleTypeList: (state) => state.demandPool.roleTypeList,
      departmentList: (state) => state.demandPool.departmentList,
    }),
  },
  watch: {
    listSelectedDepartment: {
      handler(newVal) {
        if (newVal) {
          this.form.organizationId = newVal;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      labelCol: { span: 3 },
      wrapperCol: { span: 10 },
      confirmLoading: false, // 确定按钮loading
      searchForm: {
        ...addStaffSearchForm,
      },
      table: { ...addStaffTableConfig },
      searchBtnLoading: false, // 搜索按钮loading
      form: {
        roleIds: [], // 角色类型集合
        userId: undefined, // 用户id
        organizationId: undefined, // 组织id
        openId: '',
        phoneNumber: '',
        userName: '',
        account: '',
        projectIdList:[]
      },
      rules: {
        roleIds: [{ required: true, message: '请选择角色类型', trigger: 'blur' }],
        organizationId: [{ required: true, message: '请选择部门', trigger: 'change' }],
        phoneNumber: [{ required: true, message: '请选择', trigger: 'blur' }],
        userName: [{ required: true, message: '请选择', trigger: 'change' }],
        account: [{ required: true, message: '请选择', trigger: 'change' }],
        projectIdList: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      projectList:[]
    };
  },
  methods: {
    // 查询
    search() {
      this.searchStaffInfo();
    },
    // 选中人员
    selectedStaff(items, selectedRows) {
      console.log(selectedRows);
      this.table.selectedRowKeys = items;
      this.form.userId = items[0];
      this.form.openId = selectedRows[0].openId
      this.form.userName = selectedRows[0].userName
      this.form.account = selectedRows[0].account
      this.form.phoneNumber = selectedRows[0].phoneNumber
    },
    // 提交
    submit() {
      if (!this.isSelectedStaff()) return;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.addStaff();
        }
      });
    },
    // 取消
    cancel() {
      this.$emit('update:show-add-staff', false);
    },
    /**网络请求 */
    async searchStaffInfo() {
      try {
        this.searchBtnLoading = true;
        const result = await api.addStaffSearchStaffInfoForDemandPool(this.searchForm);
        this.searchBtnLoading = false;
        if (result.code === SUCCESS_CODE) {
          this.table.dataInfo = result.data;
        }
      } catch (error) {
        this.searchBtnLoading = false;
        console.log(error);
      }
    },
    async addStaff() {
      try {
        this.confirmLoading = true;
        const result = await api.addStaffForDemandPool({ ...this.form, roleIds: this.form.roleIds.join(',') });
        this.confirmLoading = false;
        if (result.code === SUCCESS_CODE) {
          this.$message.success(result.msg);
          this.$emit('update:show-add-staff', false);
          this.$emit('refreshStaffList');
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        console.log(error);
        this.$message.error('添加人员失败');
        this.confirmLoading = false;
      }
    },
    // 校验是否选择人员
    isSelectedStaff() {
      if (!this.form.userId) {
        this.$message.warning('请选择要添加的人员');
        return false;
      }
      return true;
    },
    getTenantProject(){
      api.getTenantProject().then(res=>{
        this.projectList = res.data
        console.log(res,'>>>>>>>>>>>>>>>>>>>>>>>>>>.')
      })
    }
  },
  created(){
    this.getTenantProject()
    console.log('《《《《《《《《《《《《《《《《《《《《《《《《《《《《');
    
  }
};
</script>

<style lang="scss" scoped></style>
